import { Box } from "@mui/material";

const Logo = ({ width, height }) => {
  return (
    <Box
      component="img"
      src="/assets/logo1.png"
      sx={{
        width: width,
        height: height,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    />
  );
};

export default Logo;
